@import 'assets/styles/_mixins.scss';
@import 'assets/styles/vars/_scss-variables.scss';

.inner {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 610px;
  margin-bottom: 30px;
  line-height: 100%;
  h2 {
    font-weight: 700;
    font-size: 60px;
    letter-spacing: -0.03em;
    line-height: 100%;

    @include maxWidth($tablet-s) {
      font-size: 30px;
    }
  }

  button {
    margin-bottom: 10px;
  }
}

.subtitle {
  max-width: 525px;
  margin-bottom: 58px;
  @include maxWidth($tablet-s) {
    margin-bottom: 30px;
  }
}

.button {
  &__confirm {
    margin-bottom: 10px;
    @include maxWidth($tablet-s) {
      font-size: 18px;
    }
  }
  &__denied {
    color: $gray-medium;
    font-weight: 700;
    font-size: 24px;
    padding: 17.8402px 19.8224px 18.8313px;
    background: none;
    text-transform: initial;
    @include maxWidth($tablet-s) {
      font-size: 13px;
    }
  }
}
